import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  TextFieldVariants as MuiTextFieldVariants,
  Theme,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

export type TextFieldProps = {
  noArrows?: boolean;
  noBorder?: boolean;
  fullWidthLabel?: boolean;
} & MuiTextFieldProps;

const getSizeStyles = (customSize, theme: Theme) => {
  const { spacing } = theme;
  const sizeConfig = {
    small: {
      minHeight: spacing(7),
      padding: spacing(2),
      borderRadius: 4,
      labelTransform: "translate(9px, 4px) scale(1)",
      shrinkTransform: "translate(0px, -26px) scale(0.857142857)",
    },
    medium: {
      minHeight: spacing(8),
      padding: spacing(2.5),
      borderRadius: 4,
      labelTransform: "translate(11px, 6px) scale(1)",
      shrinkTransform: "translate(0px, -24px) scale(0.857142857)",
    },
    large: {
      minHeight: spacing(9),
      padding: spacing(3),
      borderRadius: 20,
      labelTransform: "translate(13px, 8px) scale(1)",
      shrinkTransform: "translate(13px, -24px) scale(0.857142857)",
    },
  };
  return sizeConfig[customSize];
};

const getVariantStyles = (
  variant: MuiTextFieldVariants,
  theme: Theme,
  noBorder: boolean,
) => {
  const { palette } = theme;
  const variantConfig = {
    filled: {
      background: palette.secondary["800/75"],
      border: `1px solid ${palette.secondary[700]}`,
      hoverBorder: palette.secondary[500],
      focusBorder: palette.primary[600],
      focusAfterBorder: palette.primary[900],
    },
    outlined: {
      background: "transparent",
      border: `1px solid ${palette.secondary[700]}`,
      hoverBorder: palette.secondary[500],
      focusBorder: palette.primary[600],
      focusAfterBorder: palette.primary[900],
    },
    standard: {
      background: "transparent",
      border: `1px solid transparent`,
      hoverBorder: palette.secondary[500],
      focusBorder: palette.primary[600],
      focusAfterBorder: palette.primary[900],
    },
  };
  const config = variantConfig[variant] || variantConfig.outlined;
  if (noBorder) {
    config.border = "1px solid transparent";
    config.hoverBorder = "transparent";
    config.focusBorder = "transparent";
    config.focusAfterBorder = "transparent";
  }
  return config;
};

const extraProps: PropertyKey[] = ["fullWidthLabel", "noArrows", "noBorder"];
const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<TextFieldProps>(({
  theme,
  size,
  variant,
  noArrows,
  noBorder,
  fullWidthLabel,
}) => {
  const { palette, typography } = theme as Theme;
  const sizeStyles = getSizeStyles(size, theme as Theme);
  const variantStyles = getVariantStyles(variant, theme as Theme, noBorder);

  return {
    ...(fullWidthLabel && {
      "& .MuiFormLabel-root": {
        maxWidth: "none",
      },
    }),
    ...(noArrows && {
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          display: "none",
        },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
    }),

    "& .MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel), .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
      {
        marginTop: 0,
      },
    "& .MuiInputAdornment-root": {
      color: palette.secondary[300],
    },
    "& .MuiInputBase-input": {
      marginTop: "-1px",
      color: palette.secondary[100],
      caretColor: palette.secondary[300],
      fontSize: typography.small.fontSize,
      textAlign: "inherit",
      fontWeight: size === "large" ? 500 : 400,
      boxSizing: "border-box",
      height: "100%",
      padding: 0,
    },
    "& .MuiInputBase-root": {
      minHeight: sizeStyles.minHeight,
      paddingLeft: sizeStyles.padding,
      paddingRight: sizeStyles.padding,
      borderRadius: sizeStyles.borderRadius,
      border: variantStyles.border,
      "&:not(.Mui-disabled)": {
        backgroundColor: variantStyles.background,
      },
      "&:hover:not(.Mui-disabled, .Mui-error):before, &:hover:not(.Mui-disabled, .Mui-error)":
        {
          border: `1px solid ${variantStyles.hoverBorder}`,
          borderBottomColor: variantStyles.hoverBorder,
        },
      "&.Mui-focused, &.Mui-focused:hover": {
        borderColor: variantStyles.focusBorder,
      },
      "& .MuiInputBase-root:not(.Mui-disabled):hover, & .MuiInputBase-root.Mui-focused":
        {
          backgroundColor: variantStyles.background,
        },
      "&.Mui-focused:hover:not(.Mui-disabled, .Mui-error):before": {
        borderColor: variantStyles.focusBorder,
        borderBottomColor: variantStyles.focusBorder,
      },
      "&:before": {
        top: "-1px",
        left: "-1px",
        right: "-1px",
        bottom: "-1px",
        border: `1px solid transparent`,
        borderRadius: sizeStyles.borderRadius,
      },
      "&.Mui-error:before, &.Mui-error:after": {
        borderBottomColor: noBorder && "transparent",
      },
      "&:after": {
        top: "-3px",
        left: "-3px",
        right: "-3px",
        bottom: "-3px",
        border: `2px solid ${variantStyles.focusAfterBorder}`,
        borderRadius: sizeStyles.borderRadius + 2,
      },
      "&.MuiFilledInput-root:not(.Mui-disabled):hover, &.MuiFilledInput-root.Mui-focused":
        {
          backgroundColor: variantStyles.background,
        },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `2px solid ${variantStyles.focusAfterBorder}`,
        display: "flex",
      },
    },
    "& .MuiInputBase-root:before, & .MuiInputBase-root:after": {
      transition: "none",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      top: "-3px",
      left: "-3px",
      right: "-3px",
      bottom: "-3px",
      borderRadius: sizeStyles.borderRadius + 2,
      display: "none",

      "& legend": {
        display: "none",
      },
    },
    "& .MuiInputLabel-animated": {
      fontWeight: size === "large" ? 500 : 400,
      transform: sizeStyles.labelTransform,
    },
    "& .MuiInputLabel-shrink": {
      fontWeight: 500,
      transform: sizeStyles.shrinkTransform,
    },
  };
});

export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      size = "medium",
      variant = "outlined",
      noArrows,
      fullWidthLabel,
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledTextField
        ref={ref}
        variant={variant}
        size={size}
        autoComplete="off"
        noArrows={noArrows}
        fullWidthLabel={fullWidthLabel}
        {...rest}
      />
    );
  },
);

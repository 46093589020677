import { PromptEnhancementMode, RealtimeMode } from "../../constants";
import { DimensionsType } from "../../fields/AspectRatio/types";
import { STYLE_FIELD_NAME } from "../../fields/Style/constants";
import { FormWithStyle } from "../../fields/Style/types";
import { DEFAULT_FIELDS_STYLE } from "../../fields/Style/utils";

export const DEFAULT_CREATIVITY_REALTIME = 30;
export const MIN_CREATIVITY_REALTIME = 0;
export const MAX_CREATIVITY_REALTIME = 100;

export const REALTIME_FORM_FIELDS = {
  STYLE: STYLE_FIELD_NAME,
  PROMPT_LANGUAGE: "promptLanguage",
  PROMPT_TEXT: "promptText",
  PROMPT_ENHANCEMENT_MODE: "PromptEnhancementMode",
  CREATIVITY: "creativity",
  CANVAS_IMAGE: "canvasImage",
  DIMENSIONS: "dimensions",
  MODE: "mode",
} as const;

export const DEFAULT_REALTIME_FORM_VALUES: FormTypeRealtime = {
  ...DEFAULT_FIELDS_STYLE,
  [REALTIME_FORM_FIELDS.PROMPT_LANGUAGE]: null,
  [REALTIME_FORM_FIELDS.PROMPT_TEXT]: "",
  [REALTIME_FORM_FIELDS.PROMPT_ENHANCEMENT_MODE]: "OFF",
  [REALTIME_FORM_FIELDS.CREATIVITY]: DEFAULT_CREATIVITY_REALTIME,
  [REALTIME_FORM_FIELDS.CANVAS_IMAGE]: "",
  [REALTIME_FORM_FIELDS.DIMENSIONS]: [1024, 1024],
  [REALTIME_FORM_FIELDS.MODE]: "DRAW",
};

export interface FormTypeRealtime extends FormWithStyle {
  [REALTIME_FORM_FIELDS.PROMPT_LANGUAGE]?: string;
  [REALTIME_FORM_FIELDS.PROMPT_TEXT]?: string;
  [REALTIME_FORM_FIELDS.PROMPT_ENHANCEMENT_MODE]: PromptEnhancementMode;
  [REALTIME_FORM_FIELDS.CREATIVITY]: number;
  [REALTIME_FORM_FIELDS.CANVAS_IMAGE]?: string;
  [REALTIME_FORM_FIELDS.DIMENSIONS]: DimensionsType;
  [REALTIME_FORM_FIELDS.MODE]: RealtimeMode;
}

import * as yup from "yup";

import { roundTo } from "../../../../utils/numbers";
import {
  MAX_DENOISING_STEPS,
  MAX_GUIDANCE_SCALE,
  MAX_SEED,
  MIN_DENOISING_STEPS,
  MIN_GUIDANCE_SCALE,
  MIN_SEED,
} from "../../constants";
import {
  FORM_FIELDS_UPSCALE,
  FormTypeUpscale,
  MAX_CREATIVITY,
  MAX_RESEMBLANCE,
  MAX_UPSCALE_DIMENSION,
  MAX_UPSCALE_FACTOR,
  MIN_CREATIVITY,
  MIN_RESEMBLANCE,
  MIN_UPSCALE_FACTOR,
} from "./constants";

export const getIsUpscaleTargetFileValid = (upscaleTargetFile) =>
  upscaleTargetFile.width * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION &&
  upscaleTargetFile.height * MIN_UPSCALE_FACTOR <= MAX_UPSCALE_DIMENSION;

export const validationSchema = yup
  .object()
  .shape({
    [FORM_FIELDS_UPSCALE.PROMPT_TEXT]: yup.string(),
    [FORM_FIELDS_UPSCALE.NEGATIVE_PROMPT_TEXT]: yup.string(),
    [FORM_FIELDS_UPSCALE.SEED]: yup
      .number()
      .nullable()
      .test(
        "is-valid-seed",
        `Seed must be empty or an integer between ${MIN_SEED} and ${MAX_SEED}.`,
        (value) => value === null || (value >= MIN_SEED && value <= MAX_SEED),
      ),
    [FORM_FIELDS_UPSCALE.GUIDANCE_SCALE]: yup
      .number()
      .nullable()
      .min(
        MIN_GUIDANCE_SCALE,
        `Guidance scale must be at least ${MIN_GUIDANCE_SCALE}.`,
      )
      .max(
        MAX_GUIDANCE_SCALE,
        `Guidance scale must be at most ${MAX_GUIDANCE_SCALE}.`,
      ),
    [FORM_FIELDS_UPSCALE.DENOISING_STEPS]: yup
      .number()
      .nullable()
      .min(
        MIN_DENOISING_STEPS,
        `Denoising steps must be at least ${MIN_DENOISING_STEPS}.`,
      )
      .max(
        MAX_DENOISING_STEPS,
        `Denoising steps must be at most ${MAX_DENOISING_STEPS}.`,
      ),
    [FORM_FIELDS_UPSCALE.UPSCALE_FACTOR]: yup
      .number()
      .typeError("Invalid value.")
      .required("Upscale factor is required")
      .min(MIN_UPSCALE_FACTOR, `Upscale factor at least ${MIN_UPSCALE_FACTOR}.`)
      .max(
        MAX_UPSCALE_FACTOR,
        `Scale factor must be at most ${MAX_UPSCALE_FACTOR}.`,
      )
      .when("$refinementImages", (refinementImages, schema) => {
        // Unwrap the nested array if needed
        const images = Array.isArray(refinementImages?.[0])
          ? refinementImages[0]
          : refinementImages;
        if (!images?.length) return schema;
        const lowestMaxScaleFactor = images.reduce((acc, image) => {
          if (!image?.width || !image?.height) return acc;

          const maxWidthScale = MAX_UPSCALE_DIMENSION / image.width;
          const maxHeightScale = MAX_UPSCALE_DIMENSION / image.height;

          const currentMaxScale = roundTo(
            Math.min(maxWidthScale, maxHeightScale, MAX_UPSCALE_FACTOR),
            1,
            "floor",
          );

          return Math.min(acc, currentMaxScale);
        }, MAX_UPSCALE_FACTOR);

        if (lowestMaxScaleFactor < MAX_UPSCALE_FACTOR) {
          const errorMessage =
            lowestMaxScaleFactor <= 1
              ? "Image is too large to upscale"
              : `Scale factor must be at most ${lowestMaxScaleFactor} to stay within ${MAX_UPSCALE_DIMENSION} pixels for all images.`;
          return schema.max(lowestMaxScaleFactor, errorMessage);
        }

        return schema;
      }),
    [FORM_FIELDS_UPSCALE.CREATIVITY]: yup
      .number()
      .typeError("Invalid value.")
      .nullable()
      .min(
        MIN_CREATIVITY,
        `Upscale creativity must be at least ${MIN_CREATIVITY}.`,
      )
      .max(
        MAX_CREATIVITY,
        `Upscale creativity must be at most ${MAX_CREATIVITY}.`,
      ),
    [FORM_FIELDS_UPSCALE.RESEMBLANCE]: yup
      .number()
      .typeError("Invalid value.")
      .nullable()
      .min(
        MIN_RESEMBLANCE,
        `Upscale resemblance must be at least ${MIN_RESEMBLANCE}.`,
      )
      .max(
        MAX_RESEMBLANCE,
        `Upscale resemblance must be at most ${MAX_RESEMBLANCE}.`,
      ),
  })
  .test(
    "Target File Validation",
    "You must upload at least one image to upscale.",
    function () {
      const { refinementImages } = this.options.context || {};

      if (!refinementImages?.length) return false;
      return refinementImages.every(
        (refinementImage) =>
          refinementImage?.width &&
          refinementImage?.height &&
          getIsUpscaleTargetFileValid(refinementImage),
      );
    },
  ) as yup.ObjectSchema<FormTypeUpscale>;

// Provider wrapper
import { createContext, ReactNode } from "react";
import { useRef } from "react";

import { InferenceFormsInitializer } from "./InferenceFormsInitializer";
import {
  InferenceFormProps,
  InferenceFormStore,
  inferenceFormStore,
} from "./store";

export const InferenceFormContext = createContext<InferenceFormStore | null>(
  null,
);

interface InferenceFormProviderProps extends InferenceFormProps {
  children?: ReactNode;
}

export function InferenceFormProvider({
  children,
  ...props
}: InferenceFormProviderProps) {
  const storeRef = useRef<InferenceFormStore>();

  if (!storeRef.current) {
    // create the store only once
    storeRef.current = inferenceFormStore({
      ...props,
      activeMode: null,
      refinementImages: [],
      inferenceForm2D: null,
      inferenceFormUpscale: null,
      inferenceFormVideo: null,
      inferenceForm3D: null,
      inferenceFormRealtime: null,
    });
  }

  return (
    <InferenceFormContext.Provider value={storeRef.current}>
      <InferenceFormsInitializer />
      {children}
    </InferenceFormContext.Provider>
  );
}

// Provider wrapper
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Resolver, useForm } from "react-hook-form";

import { DEFAULT_2D_FORM_VALUES } from "../forms/2D/constants";
import { FormType2D } from "../forms/2D/types";
import { validationSchema as validationSchema2D } from "../forms/2D/validationSchema";
import { DEFAULT_3D_FORM_VALUES, FormType3D } from "../forms/3D/constants";
import { validationSchema as validationSchema3D } from "../forms/3D/validationSchema";
import {
  DEFAULT_REALTIME_FORM_VALUES,
  FormTypeRealtime,
} from "../forms/Realtime/constants";
import { realtimeValidationSchema } from "../forms/Realtime/validationSchemaRealtime";
import {
  DEFAULT_UPSCALE_FORM_VALUES,
  FormTypeUpscale,
} from "../forms/Upscale/constants";
import { validationSchema as validationSchemaUpscale } from "../forms/Upscale/validationSchema";
import {
  DEFAULT_VIDEO_FORM_VALUES,
  FormTypeVideo,
} from "../forms/Video/constants";
import { validationSchema as validationSchemaVideo } from "../forms/Video/validationSchema";
import { useInferenceFormStore } from "./hook";

export const InferenceFormsInitializer = () => {
  const store = useInferenceFormStore();

  const { refinementImages } = store;

  // initialize forms with default values - these forms do not change once initialized
  const inferenceForm2D = useForm<FormType2D>({
    resolver: yupResolver(validationSchema2D) as Resolver<FormType2D>,
    mode: "onBlur",
    defaultValues: DEFAULT_2D_FORM_VALUES,
  });

  const inferenceFormUpscale = useForm<FormTypeUpscale>({
    resolver: yupResolver(validationSchemaUpscale) as Resolver<FormTypeUpscale>,
    mode: "onBlur",
    defaultValues: DEFAULT_UPSCALE_FORM_VALUES,
    context: { refinementImages },
  });

  const inferenceFormVideo = useForm<FormTypeVideo>({
    resolver: yupResolver(validationSchemaVideo) as Resolver<FormTypeVideo>,
    mode: "onBlur",
    defaultValues: DEFAULT_VIDEO_FORM_VALUES,
  });

  const inferenceForm3D = useForm<FormType3D>({
    resolver: yupResolver(validationSchema3D) as Resolver<FormType3D>,
    mode: "onBlur",
    defaultValues: DEFAULT_3D_FORM_VALUES,
  });

  const inferenceFormRealtime = useForm<FormTypeRealtime>({
    resolver: yupResolver(
      realtimeValidationSchema,
    ) as Resolver<FormTypeRealtime>,
    mode: "onBlur",
    defaultValues: DEFAULT_REALTIME_FORM_VALUES,
  });

  if (!store.inferenceForm2D) {
    store.setInferenceForm2D(inferenceForm2D);
  }

  if (!store.inferenceFormUpscale) {
    store.setInferenceFormUpscale(inferenceFormUpscale);
  }

  if (!store.inferenceFormVideo) {
    store.setInferenceFormVideo(inferenceFormVideo);
  }

  if (!store.inferenceForm3D) {
    store.setInferenceForm3D(inferenceForm3D);
  }

  if (!store.inferenceFormRealtime) {
    store.setInferenceFormRealtime(inferenceFormRealtime);
  }

  useEffect(() => {
    inferenceFormUpscale.trigger();
  }, [refinementImages, inferenceFormUpscale]);

  return null;
};

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import * as React from "react";

type LayerThemeProviderProps = {
  children: React.ReactNode;
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    title1: React.CSSProperties;
    title2: React.CSSProperties;
    title3: React.CSSProperties;
    title4: React.CSSProperties;
    large: React.CSSProperties;
    regular: React.CSSProperties;
    small: React.CSSProperties;
    mini: React.CSSProperties;
    micro: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title1?: React.CSSProperties;
    title2?: React.CSSProperties;
    title3?: React.CSSProperties;
    title4?: React.CSSProperties;
    large?: React.CSSProperties;
    regular?: React.CSSProperties;
    small?: React.CSSProperties;
    mini?: React.CSSProperties;
    micro?: React.CSSProperties;
  }
}

// Update the Palette options
declare module "@mui/material/styles" {
  interface PaletteColor {
    1000?: string;
    950?: string;
    900?: string;
    800?: string;
    700?: string;
    600?: string;
    500?: string;
    400?: string;
    300?: string;
    200?: string;
    100?: string;
    50?: string;
  }

  interface SimplePaletteColorOptions {
    1000?: string;
    "1000/75"?: string;
    "1000/50"?: string;
    "1000/25"?: string;
    950?: string;
    "950/75"?: string;
    "950/50"?: string;
    "950/25"?: string;
    900?: string;
    "900/75"?: string;
    "900/50"?: string;
    "900/25"?: string;
    800?: string;
    "800/75"?: string;
    "800/50"?: string;
    "800/25"?: string;
    700?: string;
    "700/75"?: string;
    "700/50"?: string;
    "700/25"?: string;
    600?: string;
    "600/75"?: string;
    "600/50"?: string;
    "600/25"?: string;
    500?: string;
    "500/75"?: string;
    "500/50"?: string;
    "500/25"?: string;
    400?: string;
    "400/75"?: string;
    "400/50"?: string;
    "400/25"?: string;
    300?: string;
    "300/75"?: string;
    "300/50"?: string;
    "300/25"?: string;
    200?: string;
    "200/75"?: string;
    "200/50"?: string;
    "200/25"?: string;
    100?: string;
    "100/75"?: string;
    "100/50"?: string;
    "100/25"?: string;
    50?: string;
    "50/75"?: string;
    "50/50"?: string;
    "50/25"?: string;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    title1: true;
    title2: true;
    title3: true;
    title4: true;
    large: true;
    regular: true;
    small: true;
    mini: true;
    micro: true;
  }
}

export const Palette = {
  TRANSPARENT: "rgba(255,255,255,0)",
  WHITE: "#ffffff",
  BLACK: "#000000",
  PRIMARY: {
    950: "#3B0764",
    900: "#581C87",
    800: "#6B21A8",
    700: "#7E22CE",
    600: "#9333EA",
    500: "#A855F7",
    400: "#C084FC",
    300: "#D8B4FE",
    200: "#E9D5FF",
    100: "#F3E8FF",
    50: "#FAF5FF",
  },
  SECONDARY: {
    1000: "#15161D",
    "1000/75": "rgba(21,22,29,0.75)",
    "1000/50": "rgba(21,22,29,0.50)",
    "1000/25": "rgba(21,22,29,0.25)",
    950: "#191A23",
    "950/75": "rgba(25,26,35,0.75)",
    "950/50": "rgba(25,26,35,0.50)",
    "950/25": "rgba(25,26,35,0.25)",
    900: "#1E1F2A",
    "900/75": "rgba(30,31,42,0.75)",
    "900/50": "rgba(30,31,42,0.50)",
    "900/25": "rgba(30,31,42,0.25)",
    800: "#232434",
    "800/75": "rgba(35,36,52,0.75)",
    "800/50": "rgba(35,36,52,0.50)",
    "800/25": "rgba(35,36,52,0.25)",
    700: "#2C2D3A",
    "700/75": "rgba(44,45,58,0.75)",
    "700/50": "rgba(44,45,58,0.50)",
    "700/25": "rgba(44,45,58,0.25)",
    600: "#313248",
    "600/75": "rgba(49,50,72,0.75)",
    "600/50": "rgba(49,50,72,0.50)",
    "600/25": "rgba(49,50,72,0.25)",
    500: "#41425B",
    "500/75": "rgba(65,66,91,0.75)",
    "500/50": "rgba(65,66,91,0.50)",
    "500/25": "rgba(65,66,91,0.25)",
    400: "#52526F",
    "400/75": "rgba(82,82,111,0.75)",
    "400/50": "rgba(82,82,111,0.50)",
    "400/25": "rgba(82,82,111,0.25)",
    300: "#858699",
    "300/75": "rgba(133,134,153,0.75)",
    "300/50": "rgba(133,134,153,0.50)",
    "300/25": "rgba(133,134,153,0.25)",
    200: "#D2D3E0",
    "200/75": "rgba(210,211,224,0.75)",
    "200/50": "rgba(210,211,224,0.50)",
    "200/25": "rgba(210,211,224,0.25)",
    100: "#E6E6EF",
    "100/75": "rgba(230,230,239,0.75)",
    "100/50": "rgba(230,230,239,0.50)",
    "100/25": "rgba(230,230,239,0.25)",
    50: "#FAFAFF",
    "50/75": "rgba(250,250,255,0.75)",
    "50/50": "rgba(250,250,255,0.50)",
    "50/25": "rgba(250,250,255,0.25)",
  },
  SUCCESS: {
    950: "#083344",
    900: "#164E63",
    800: "#155E75",
    700: "#0E7490",
    600: "#0891B2",
    500: "#06B6D4",
    400: "#22D3EE",
    300: "#67E8F9",
    200: "#A5F3FC",
    100: "#CFFAFE",
    50: "#ECFEFF",
  },
  WARNING: {
    950: "#451a03",
    900: "#78350F",
    800: "#92400E",
    700: "#B45309",
    600: "#D97706",
    500: "#F59E0B",
    400: "#FBBF24",
    300: "#FCD34D",
    200: "#FDE68A",
    100: "#FEF3C7",
    50: "#FFFBEB",
  },
  ERROR: {
    950: "#500724",
    900: "#831843",
    800: "#9D174D",
    700: "#BE185D",
    600: "#DB2777",
    500: "#EC4899",
    400: "#F472B6",
    300: "#F9A8D4",
    200: "#FBCFE8",
    100: "#FCE7F3",
    50: "#FDF2F8",
  },
};

const fontFamily = [
  "inter",
  "ui-sans-serif",
  "system-ui",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "Noto Sans",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji",
].join(",");

const typography: TypographyOptions = {
  fontFamily,
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  fontSize: 14,
  title1: {
    fontSize: 36,
  },
  title2: {
    fontSize: 24,
  },
  title3: {
    fontSize: 22,
  },
  title4: {
    fontSize: 20,
  },
  large: {
    fontSize: 18,
  },
  regular: {
    fontSize: 14,
  },
  small: {
    fontSize: 13,
  },
  mini: {
    fontSize: 12,
  },
  micro: {
    fontSize: 11,
  },
};

export const theme = createTheme({
  typography,
  palette: {
    mode: "dark",
    primary: {
      dark: Palette.PRIMARY[600],
      main: Palette.PRIMARY[500],
      light: Palette.PRIMARY[400],
      contrastText: Palette.PRIMARY[50],
      950: Palette.PRIMARY[950],
      900: Palette.PRIMARY[900],
      800: Palette.PRIMARY[800],
      700: Palette.PRIMARY[700],
      600: Palette.PRIMARY[600],
      500: Palette.PRIMARY[500],
      400: Palette.PRIMARY[400],
      300: Palette.PRIMARY[300],
      200: Palette.PRIMARY[200],
      100: Palette.PRIMARY[100],
      50: Palette.PRIMARY[50],
    },
    secondary: {
      dark: Palette.PRIMARY[800],
      main: Palette.PRIMARY[700],
      light: Palette.PRIMARY[600],
      contrastText: Palette.PRIMARY[50],
      1000: Palette.SECONDARY[1000],
      "1000/75": Palette.SECONDARY["1000/75"],
      "1000/50": Palette.SECONDARY["1000/50"],
      "1000/25": Palette.SECONDARY["1000/25"],
      950: Palette.SECONDARY[950],
      "950/75": Palette.SECONDARY["950/75"],
      "950/50": Palette.SECONDARY["950/50"],
      "950/25": Palette.SECONDARY["950/25"],
      900: Palette.SECONDARY[900],
      "900/75": Palette.SECONDARY["900/75"],
      "900/50": Palette.SECONDARY["900/50"],
      "900/25": Palette.SECONDARY["900/25"],
      800: Palette.SECONDARY[800],
      "800/75": Palette.SECONDARY["800/75"],
      "800/50": Palette.SECONDARY["800/50"],
      "800/25": Palette.SECONDARY["800/25"],
      700: Palette.SECONDARY[700],
      "700/75": Palette.SECONDARY["700/75"],
      "700/50": Palette.SECONDARY["700/50"],
      "700/25": Palette.SECONDARY["700/25"],
      600: Palette.SECONDARY[600],
      "600/75": Palette.SECONDARY["600/75"],
      "600/50": Palette.SECONDARY["600/50"],
      "600/25": Palette.SECONDARY["600/25"],
      500: Palette.SECONDARY[500],
      "500/75": Palette.SECONDARY["500/75"],
      "500/50": Palette.SECONDARY["500/50"],
      "500/25": Palette.SECONDARY["500/25"],
      400: Palette.SECONDARY[400],
      "400/75": Palette.SECONDARY["400/75"],
      "400/50": Palette.SECONDARY["400/50"],
      "400/25": Palette.SECONDARY["400/25"],
      300: Palette.SECONDARY[300],
      "300/75": Palette.SECONDARY["300/75"],
      "300/50": Palette.SECONDARY["300/50"],
      "300/25": Palette.SECONDARY["300/25"],
      200: Palette.SECONDARY[200],
      "200/75": Palette.SECONDARY["200/75"],
      "200/50": Palette.SECONDARY["200/50"],
      "200/25": Palette.SECONDARY["200/25"],
      100: Palette.SECONDARY[100],
      "100/75": Palette.SECONDARY["100/75"],
      "100/50": Palette.SECONDARY["100/50"],
      "100/25": Palette.SECONDARY["100/25"],
      50: Palette.SECONDARY[50],
      "50/75": Palette.SECONDARY["50/75"],
      "50/50": Palette.SECONDARY["50/50"],
      "50/25": Palette.SECONDARY["50/25"],
    },
    success: {
      dark: Palette.SUCCESS[500],
      main: Palette.SUCCESS[400],
      light: Palette.SUCCESS[300],
      contrastText: Palette.SUCCESS[950],
      950: Palette.SUCCESS[950],
      900: Palette.SUCCESS[900],
      800: Palette.SUCCESS[800],
      700: Palette.SUCCESS[700],
      600: Palette.SUCCESS[600],
      500: Palette.SUCCESS[500],
      400: Palette.SUCCESS[400],
      300: Palette.SUCCESS[300],
      200: Palette.SUCCESS[200],
      100: Palette.SUCCESS[100],
      50: Palette.SUCCESS[50],
    },
    warning: {
      dark: Palette.WARNING[500],
      main: Palette.WARNING[400],
      light: Palette.WARNING[300],
      contrastText: Palette.WARNING[950],
      950: Palette.WARNING[950],
      900: Palette.WARNING[900],
      800: Palette.WARNING[800],
      700: Palette.WARNING[700],
      600: Palette.WARNING[600],
      500: Palette.WARNING[500],
      400: Palette.WARNING[400],
      300: Palette.WARNING[300],
      200: Palette.WARNING[200],
      100: Palette.WARNING[100],
      50: Palette.WARNING[50],
    },
    error: {
      dark: Palette.ERROR[700],
      main: Palette.ERROR[600],
      light: Palette.ERROR[500],
      contrastText: Palette.ERROR[50],
      950: Palette.ERROR[950],
      900: Palette.ERROR[900],
      800: Palette.ERROR[800],
      700: Palette.ERROR[700],
      600: Palette.ERROR[600],
      500: Palette.ERROR[500],
      400: Palette.ERROR[400],
      300: Palette.ERROR[300],
      200: Palette.ERROR[200],
      100: Palette.ERROR[100],
      50: Palette.ERROR[50],
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily,
          textAlign: "left",
          backgroundColor: Palette.SECONDARY[1000],
        },
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "inherit",
        },
      },
      defaultProps: {
        color: "inherit",
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: typography.regular.fontSize,
          "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: Palette.SECONDARY[900],
          backgroundImage: "none",
          borderRadius: "8px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          padding: "2px 0",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          fontSize: typography.small.fontSize,
          lineHeight: typography.small.lineHeight,
          "& .MuiDivider-root": {
            borderColor: Palette.SECONDARY[700],
            margin: "8px 0",
          },
        },
        paper: {
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
          minWidth: 210,
          backgroundColor: Palette.SECONDARY[900],
          border: `1px solid ${Palette.SECONDARY[700]}`,
          borderRadius: "8px",
          padding: "4px 0",
        },
      },
    },
    MuiMenuList: {
      styleOverrides: {
        root: {
          fontSize: typography.small.fontSize,
          lineHeight: typography.small.lineHeight,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "4px 6px",
          margin: "4px 8px",
          borderRadius: 4,
          border: `1px solid transparent`,
          minHeight: 32,
          "& .MuiListItemText-root": {
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            gap: "12px",
          },
          "& .MuiListItemText-primary, & .MuiListItemText-secondary": {
            fontSize: typography.small.fontSize,
          },
          "& .MuiListItemText-secondary": {
            color: Palette.SECONDARY[300],
          },
          "&.Mui-focusVisible": {
            backgroundColor: Palette.SECONDARY["600/50"],
            borderColor: Palette.SECONDARY["500/25"],
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: Palette.SECONDARY["600/75"],
            borderColor: Palette.SECONDARY["500/25"],
            color: Palette.SECONDARY[50],
          },
          ":hover": {
            backgroundColor: Palette.SECONDARY["600/50"],
            borderColor: Palette.SECONDARY["500/25"],
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
      variants: [
        {
          props: {
            variant: "primary",
          },
          style: {
            ".MuiListItemText-root": {
              color: Palette.SECONDARY[100],
              fontWeight: 500,
            },
            ".MuiListItemIcon-root": {
              color: Palette.SECONDARY[100],
            },
          },
        },
        {
          props: {
            variant: "secondary",
          },
          style: {
            ".MuiListItemText-root": {
              color: Palette.SECONDARY[100],
              fontWeight: 400,
            },
            ".MuiListItemIcon-root": {
              color: Palette.SECONDARY[300],
            },
          },
        },
        {
          props: {
            variant: "tertiary",
          },
          style: {
            ".MuiListItemText-root": {
              color: Palette.SECONDARY[300],
              fontWeight: 400,
            },
            ".MuiListItemIcon-root": {
              color: Palette.SECONDARY[300],
            },
          },
        },
      ],
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 24,
          "& svg": {
            fontSize: "inherit",
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: Palette.WHITE,
          borderRadius: 4,
          border: `1px solid ${Palette.TRANSPARENT}`,

          "&:disabled": {
            opacity: 0.5,
          },
        },
        sizeLarge: {
          padding: 8,
          fontSize: 13,
          minWidth: 32,
          minHeight: 32,
          maxHeight: 32,
        },
        sizeMedium: {
          padding: 6,
          fontSize: 13,
          minWidth: 28,
          minHeight: 28,
          maxHeight: 28,
        },
        sizeSmall: {
          padding: 6,
          fontSize: 12,
          minWidth: 24,
          minHeight: 24,
          maxHeight: 24,
        },
      },
    },

    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // All Buttons
        root: {
          lineHeight: 1,
          textTransform: "none",
          boxSizing: "border-box",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.09)",
          whiteSpace: "nowrap",
          borderRadius: 4,
          fontWeight: 500,

          height: "fit-content",
          width: "fit-content",
          minWidth: "unset",

          "&.MuiButton-fullWidth": {
            width: "100%",
            minWidth: "100%",

            "& .MuiButton-endIcon": {
              marginLeft: "auto",
            },
          },

          "& .MuiLoadingButton-loadingIndicatorStart": {
            left: 6,
          },

          "& .MuiButton-startIcon": {
            fontSize: 13,
          },

          "& .MuiButton-endIcon": {
            fontSize: 13,
            marginBottom: -1,
          },

          "&.MuiButton-sizeLarge": {
            "&.MuiButton-startIcon": {
              fontSize: 13,
            },

            "&.MuiButton-endIcon": {
              fontSize: 13,
            },
          },

          "&.MuiButton-sizeMedium": {
            "&.MuiButton-startIcon": {
              fontSize: 13,
            },

            "&.MuiButton-endIcon": {
              fontSize: 13,
            },
          },

          "&.MuiButton-sizeSmall": {
            "&.MuiButton-startIcon": {
              fontSize: 12,
            },

            "&.MuiButton-endIcon": {
              fontSize: 12,
            },
          },
        },
        // Contained
        containedPrimary: {
          backgroundColor: Palette.PRIMARY[600],
          color: Palette.PRIMARY[50],

          "&.MuiButton-endIcon": {
            color: Palette.PRIMARY[200],
          },

          "&:hover": {
            backgroundColor: Palette.PRIMARY[500],
            color: Palette.WHITE,
          },

          "&:disabled": {
            backgroundColor: Palette.PRIMARY[600],
            color: Palette.PRIMARY[200],
            opacity: 0.5,
          },
        },
        containedSecondary: {
          backgroundColor: Palette.SECONDARY["400/25"],
          border: `1px solid ${Palette.SECONDARY["400/50"]}`,

          "&.MuiButton-endIcon": {
            color: Palette.SECONDARY[300],
          },

          "&:hover": {
            backgroundColor: Palette.SECONDARY["400/25"],
            border: `1px solid ${Palette.SECONDARY[400]}`,
          },

          "&:disabled": {
            backgroundColor: Palette.SECONDARY["400/25"],
            color: Palette.SECONDARY[200],
            opacity: 0.5,
          },

          "& .MuiButton-startIcon": {
            color: Palette.SECONDARY[300],
          },
        },
        containedSuccess: {
          backgroundColor: Palette.SUCCESS[400],

          "&.MuiButton-endIcon": {
            color: Palette.SUCCESS[800],
          },

          "&:hover": {
            backgroundColor: Palette.SUCCESS[300],
          },

          "&:disabled": {
            backgroundColor: Palette.SUCCESS[400],
            color: Palette.SUCCESS[800],
            opacity: 0.5,
          },
        },
        containedWarning: {
          backgroundColor: Palette.WARNING[400],
          color: Palette.SECONDARY[800],

          "&.MuiButton-endIcon": {
            color: Palette.SECONDARY[400],
          },

          "&:hover": {
            backgroundColor: Palette.WARNING[300],
          },

          "&:disabled": {
            backgroundColor: Palette.WARNING[400],
            color: Palette.SECONDARY[800],
            opacity: 0.5,
          },
        },
        containedError: {
          backgroundColor: Palette.ERROR[600],

          "&.MuiButton-endIcon": {
            color: Palette.ERROR[300],
          },

          "&:hover": {
            backgroundColor: Palette.ERROR[500],
          },

          "&:disabled": {
            backgroundColor: Palette.ERROR[500],
            color: Palette.ERROR[50],
            opacity: 0.5,
          },
        },
        // Outlined
        outlined: {
          border: `1px solid ${Palette.WHITE}`,
        },
        outlinedPrimary: {
          backgroundColor: Palette.SECONDARY[800],
          border: `1px solid ${Palette.PRIMARY[900]}`,
          color: Palette.PRIMARY[400],

          "&.MuiButton-endIcon": {
            color: Palette.PRIMARY[600],
          },

          "&:hover": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.PRIMARY[600]}`,
            color: Palette.PRIMARY[300],
          },

          "&:disabled": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.PRIMARY[900]}`,
            color: Palette.PRIMARY[400],
            opacity: 0.5,
          },
        },
        outlinedSecondary: {
          backgroundColor: Palette.SECONDARY[800],
          border: `1px solid ${Palette.SECONDARY["500/50"]}`,
          color: Palette.SECONDARY[200],

          "&.MuiButton-endIcon": {
            color: Palette.SECONDARY[300],
          },

          "&:hover": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.SECONDARY[500]}`,
            color: Palette.SECONDARY[100],
          },

          "&:disabled": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.SECONDARY[700]}`,
            color: Palette.SECONDARY[200],
            opacity: 0.5,
          },
        },
        outlinedSuccess: {
          backgroundColor: Palette.SECONDARY[800],
          border: `1px solid ${Palette.SUCCESS[900]}`,
          color: Palette.SUCCESS[300],

          "&.MuiButton-endIcon": {
            color: Palette.SUCCESS[600],
          },

          "&:hover": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.SUCCESS[700]}`,
            color: Palette.SUCCESS[200],
          },

          "&:disabled": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.SUCCESS[900]}`,
            color: Palette.SUCCESS[300],
            opacity: 0.5,
          },
        },
        outlinedWarning: {
          backgroundColor: Palette.SECONDARY[800],
          border: `1px solid ${Palette.WARNING[900]}`,
          color: Palette.WARNING[500],

          "&.MuiButton-endIcon": {
            color: Palette.WARNING[700],
          },

          "&:hover": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.WARNING[800]}`,
            color: Palette.WARNING[400],
          },

          "&:disabled": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.WARNING[900]}`,
            color: Palette.WARNING[500],
            opacity: 0.5,
          },
        },
        outlinedError: {
          backgroundColor: Palette.SECONDARY[800],
          border: `1px solid ${Palette.ERROR[900]}`,
          color: Palette.ERROR[500],

          "&.MuiButton-endIcon": {
            color: Palette.ERROR[600],
          },

          "&:hover": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.ERROR[700]}`,
            color: Palette.ERROR[400],
          },

          "&:disabled": {
            backgroundColor: Palette.SECONDARY[800],
            border: `1px solid ${Palette.ERROR[900]}`,
            color: Palette.ERROR[500],
            opacity: 0.5,
          },
        },
        // Text
        text: {
          textAlign: "left",
          border: `1px solid ${Palette.TRANSPARENT}`,
        },
        textPrimary: {
          color: Palette.PRIMARY[400],

          "&.MuiButton-endIcon": {
            color: Palette.PRIMARY[600],
          },

          "&:hover": {
            color: Palette.PRIMARY[300],
          },

          "&:disabled": {
            color: Palette.PRIMARY[400],
            opacity: 0.5,
          },
        },
        textSecondary: {
          color: Palette.SECONDARY[200],

          "&.MuiButton-startIcon": {
            color: Palette.SECONDARY[300],
          },

          "&.MuiButton-endIcon": {
            color: Palette.SECONDARY[400],
          },

          "&:hover": {
            backgroundColor: Palette.SECONDARY["600/25"],
            color: Palette.SECONDARY[100],
          },

          "&:disabled": {
            color: Palette.SECONDARY[300],
            opacity: 0.5,
          },
        },
        textSuccess: {
          color: Palette.SUCCESS[400],

          "&.MuiButton-endIcon": {
            color: Palette.SUCCESS[600],
          },

          "&:hover": {
            color: Palette.SUCCESS[300],
          },

          "&:disabled": {
            color: Palette.SUCCESS[400],
            opacity: 0.5,
          },
        },
        textWarning: {
          color: Palette.WARNING[400],

          "&.MuiButton-endIcon": {
            color: Palette.WARNING[700],
          },

          "&:hover": {
            backgroundColor: "rgba(251,191,36,0.1)",
            color: Palette.WARNING[300],
          },

          "&:disabled": {
            color: Palette.WARNING[400],
            opacity: 0.5,
          },
        },
        textError: {
          color: Palette.ERROR[500],

          "&.MuiButton-endIcon": {
            color: Palette.ERROR[600],
          },

          "&:hover": {
            backgroundColor: "rgba(236,72,153,0.1)",
            color: Palette.ERROR[400],
          },

          "&:disabled": {
            color: Palette.ERROR[500],
            opacity: 0.5,
          },
        },
        // Icon
        startIcon: {
          marginLeft: -2,
          marginRight: 5,

          "& >*:nth-of-type(1)": {
            fontSize: "inherit",
          },
        },
        endIcon: {
          marginLeft: 5,
          marginRight: 0,

          "& >*:nth-of-type(1)": {
            fontSize: "inherit",
          },
        },
        // Sizes
        sizeLarge: {
          padding: "4px 8px",
          fontSize: 14,
          minHeight: 32,
          maxHeight: 32,
          height: "100%",

          "&.MuiButton-startIcon": {
            fontSize: 14,
          },

          "&.MuiButton-endIcon": {
            fontSize: 14,
          },
        },
        sizeMedium: {
          padding: "4px 8px",
          fontSize: 14,
          minHeight: 28,
          maxHeight: 28,
          height: "100%",

          "&.MuiButton-startIcon": {
            fontSize: 14,
          },

          "&.MuiButton-endIcon": {
            fontSize: 14,
          },

          "& .MuiCircularProgress-root ": {
            height: 14,
            width: 14,
          },
        },
        sizeSmall: {
          padding: "2px 4px",
          fontSize: 12,
          minHeight: 24,
          maxHeight: 24,
          height: "100%",

          "&.MuiButton-startIcon": {
            fontSize: 12,
          },

          "&.MuiButton-endIcon": {
            fontSize: 12,
          },
        },
        iconSizeSmall: {
          fontSize: 12,
        },
        iconSizeMedium: {
          fontSize: 16,
        },
        iconSizeLarge: {
          fontSize: 18,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          minWidth: 20,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
        animated: {
          color: Palette.SECONDARY[300],
          fontSize: typography.small.fontSize,
        },
        shrink: {
          transform: "scale(0.857142857)",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: "regular",
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          ":before": {
            borderBottom: "0 !important",
          },
          ":after": {
            borderBottom: "0 !important",
          },
        },
        standard: {
          ":focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${Palette.SECONDARY["600"]}`,
          boxShadow: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
        filled: {
          backgroundColor: Palette.SECONDARY["600/25"],
        },
        outlined: {
          backgroundColor: Palette.TRANSPARENT,
        },
        filledPrimary: {
          backgroundColor: Palette.PRIMARY[400],
          color: Palette.PRIMARY[50],
        },
        outlinedPrimary: {
          border: `1px solid ${Palette.PRIMARY[600]}`,
          color: Palette.PRIMARY[600],
        },
        filledSecondary: {
          backgroundColor: Palette.SECONDARY["500/50"],
          boxShadow: `inset 0 0 0 0.5px ${Palette.SECONDARY["500/50"]}`,
          color: Palette.SECONDARY[300],
        },
        outlinedSecondary: {
          boxShadow: `inset 0 0 0 0.5px ${Palette.SECONDARY["300/50"]}`,
          border: "none",
          backgroundColor: Palette.SECONDARY[800],
          color: Palette.SECONDARY[300],
        },
        // @ts-ignore - this field is valid, but typescript is unhappy about it
        filledSuccess: {
          backgroundColor: Palette.SUCCESS[800],
          color: Palette.SUCCESS[200],
        },
        outlinedSuccess: {
          border: "none",
          boxShadow: `inset 0 0 0 0.5px ${Palette.SUCCESS["300/50"]}`,
          color: Palette.SUCCESS[500],
        },
        filledWarning: {
          backgroundColor: Palette.WARNING[800],
          color: Palette.WARNING[50],
        },
        // @ts-ignore - this field is valid, but typescript is unhappy about it
        outlinedWarning: {
          border: "none",
          boxShadow: `inset 0 0 0 0.5px ${Palette.WARNING["300/50"]}`,
          color: Palette.WARNING[500],
        },
        filledError: {
          backgroundColor: Palette.ERROR[800],
          color: Palette.ERROR[50],
        },
        // @ts-ignore - this field is valid, but typescript is unhappy about it
        outlinedError: {
          border: `1px solid ${Palette.ERROR[500]}`,
          color: Palette.ERROR[500],
        },
        sizeSmall: {
          height: 20,
          fontSize: 12,
        },
        sizeMedium: {
          height: 24,
          fontSize: 14,
        },
        sizeLarge: {
          height: 28,
          fontSize: 16,
        },
        labelSmall: {
          paddingLeft: "8px",
          paddingRight: "8px",
        },
        labelMedium: {
          padding: "4px 8px",
        },
        labelLarge: {
          padding: "4px 8px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "4px",
          textTransform: "none",
          minHeight: "auto",
          minWidth: "auto",
          padding: "4px 8px",
          fontSize: "14px",
          fontWeight: "normal",
          gap: "4px",
          "&.Mui-selected": {
            color: Palette.SECONDARY[50],
            border: `1px solid ${Palette.SECONDARY[700]}`,
            backgroundColor: Palette.SECONDARY[800],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "auto",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: "14px",
          lineHeight: "1.25rem",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          padding: `1rem 0 0`,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: Palette.SECONDARY[300],
          "&.Mui-active": {
            color: Palette.SECONDARY[50],
          },
        },
        iconContainer: {
          "&.Mui-completed": {
            color: Palette.SUCCESS[500],
            fontWeight: 600,
          },
          "&.Mui-active": {
            color: Palette.SUCCESS[500],
            fontWeight: 600,
          },
          "&.Mui-disabled": {
            color: Palette.SECONDARY[500],
            fontWeight: 600,
          },
        },
      },
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          lineHeight: "inherit",
          fontSize: "inherit",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderWidth: "1px",
          borderColor: Palette.TRANSPARENT,
        },
        message: {
          display: "flex",
          alignItems: "center",
          padding: "1px 0 3px !important",
        },
        icon: {
          marginRight: "8px",
          display: "flex",
          alignItems: "center",
        },
        standardInfo: {
          backgroundColor: Palette.PRIMARY[950],
          borderColor: Palette.PRIMARY[950],
          color: Palette.PRIMARY[50],

          "& .MuiAlert-icon": {
            color: Palette.PRIMARY[500],
          },
        },
        standardSuccess: {
          backgroundColor: Palette.SUCCESS[950],
          borderColor: Palette.SUCCESS[950],
          color: Palette.SUCCESS[50],

          "& .MuiAlert-icon": {
            color: Palette.SUCCESS[500],
          },
        },
        standardWarning: {
          backgroundColor: Palette.WARNING[900],
          borderColor: Palette.WARNING[900],
          color: Palette.WARNING[50],

          "& .MuiAlert-icon": {
            color: Palette.WARNING[500],
          },
        },
        standardError: {
          backgroundColor: Palette.ERROR[950],
          borderColor: Palette.ERROR[950],
          color: Palette.ERROR[50],

          "& .MuiAlert-icon": {
            color: Palette.ERROR[500],
          },
        },
        action: {
          padding: "0 4px",

          "& .MuiIconButton-root": {
            padding: "2px",
            height: "100%",
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: 16,
          height: 16,
        },
        root: {
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          border: `1px solid ${Palette.SECONDARY[600]}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 500,
        enterNextDelay: 0,
      },
      styleOverrides: {
        tooltip: {
          borderRadius: "4px",
          backgroundColor: Palette.SECONDARY[100],
          color: Palette.SECONDARY[700],
        },
        arrow: {
          color: Palette.SECONDARY[100],
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: "›",
      },
      styleOverrides: {
        root: {
          fontSize: 13,

          "& .MuiBreadcrumbs-separator": {
            color: Palette.SECONDARY[300],
          },

          "&& .MuiBreadcrumbs-li": {
            color: Palette.SECONDARY[300],

            "&:hover": {
              color: Palette.SECONDARY[50],
            },

            "&:last-child": {
              color: Palette.SECONDARY[50],
            },
          },
        },
      },
    },
  },
});

export const LayerThemeProvider = ({ children }: LayerThemeProviderProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

import { UseFormReturn } from "react-hook-form";
import { createStore } from "zustand";

import { SessionInferenceMode } from "../../graphql/schema.graphql";
import { ReferenceImageType } from "../fields/References/types";
import { FormType2D } from "../forms/2D/types";
import { FormType3D } from "../forms/3D/constants";
import { FormTypeRealtime } from "../forms/Realtime/constants";
import { FormTypeUpscale } from "../forms/Upscale/constants";
import { FormTypeVideo } from "../forms/Video/constants";
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InferenceFormProps {}

export interface InferenceFormInternalProps extends InferenceFormProps {
  activeMode: SessionInferenceMode;
  refinementImages: ReferenceImageType[];
  inferenceForm2D: UseFormReturn<FormType2D>;
  inferenceFormUpscale: UseFormReturn<FormTypeUpscale>;
  inferenceFormVideo: UseFormReturn<FormTypeVideo>;
  inferenceForm3D: UseFormReturn<FormType3D>;
  inferenceFormRealtime: UseFormReturn<FormTypeRealtime>;
}

export interface InferenceFormState extends InferenceFormInternalProps {
  setActiveMode: (mode: SessionInferenceMode) => void;
  setRefinementImages: (images: ReferenceImageType[]) => void;
  setInferenceForm2D: (form: UseFormReturn<FormType2D>) => void;
  setInferenceForm3D: (form: UseFormReturn<FormType3D>) => void;
  setInferenceFormUpscale: (form: UseFormReturn<FormTypeUpscale>) => void;
  setInferenceFormVideo: (form: UseFormReturn<FormTypeVideo>) => void;
  setInferenceFormRealtime: (form: UseFormReturn<FormTypeRealtime>) => void;
}

export const inferenceFormStore = (initProps: InferenceFormInternalProps) => {
  return createStore<InferenceFormState>()((set) => ({
    ...initProps,
    setActiveMode: (activeMode) => set(() => ({ activeMode })),
    setRefinementImages: (refinementImages) =>
      set(() => ({ refinementImages })),
    setInferenceForm2D: (inferenceForm2D) => set(() => ({ inferenceForm2D })),
    setInferenceForm3D: (inferenceForm3D) => set(() => ({ inferenceForm3D })),
    setInferenceFormUpscale: (inferenceFormUpscale) =>
      set(() => ({ inferenceFormUpscale })),
    setInferenceFormVideo: (inferenceFormVideo) =>
      set(() => ({ inferenceFormVideo })),
    setInferenceFormRealtime: (inferenceFormRealtime) =>
      set(() => ({ inferenceFormRealtime })),
  }));
};

export type InferenceFormStore = ReturnType<typeof inferenceFormStore>;
